// VARIABLE DECLARATIONS
$blue-light: #084356;
$blue-dark: #003049;
$red-dark: #990011FF;
$new-secondary: rgb(215, 202, 200);


// DEFAULT VARIABLE OVERRIDING
$primary: $blue-dark;
$danger: $red-dark;
$input-btn-focus-box-shadow: none !important;
$btn-box-shadow: none !important;
$btn-focus-box-shadow: none !important;
$btn-active-box-shadow: none !important;


// MIXINS
@mixin gradient {
    background: linear-gradient(to right, rgba($blue-dark, 0.95), rgba($blue-light, 0.95));
}

// STYLINGS
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // border: 1px solid red;
}

body {
    overflow-x: hidden;
}

.form-control.invalid:focus {
    border-color: rgb(220, 53, 69);
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.gradient {
    @include gradient;
}


.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #ff1361 67%,
            #fff800 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-family: var(--roboto);
    font-size: 2.5rem;
    font-weight: 400;
}


// create a class that hides the element on screen size lesser than 1167px
@media (max-width: 1168px) {
    .hide-on-less-than-1168px {
        display: none !important;
    }
}

.footer-link {
    color: #FFF;
    text-decoration: none;

    &:hover {
        color: #FFF;
        text-decoration: underline;
    }
}

.footer-social-media-link {
    transition: all 0.3s ease-in-out;

    svg:hover path {
        fill: #cccccc;
    }


    &:hover {
        text-decoration: underline;
    }
}

.bg-grey {
    background-color: #F6F6F6;
}


.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    // add transition on border and background color
    transition: all 0.15s ease-in-out;
    // borderRadius: '0.375rem',
    border-radius: 0.375rem;
}

// textarea{
//     border-radius: 0.375rem;
// }


input:disabled {
    opacity: 0.5;
}



.PhoneInputInput:focus,
.general-input:focus {
    border: 1px solid #ced4da !important;
    background-color: #fcfcfc !important;
}


// textarea:focus{
//     border:1px solid #ced4da !important;
//     background-color: #fcfcfc !important;
// }




// placeholder color
::-webkit-input-placeholder {
    color: #a9a9a9;
}


.tableLinks,
.general-links {
    color: #555;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #080808;
        text-decoration: underline;
    }
}




/* custom scrollbar */
// ::-webkit-scrollbar {
//     width: 6px;
// }

// ::-webkit-scrollbar-track {
//     background-color: transparent;
// }

// ::-webkit-scrollbar-thumb {
//     background-color: #d6dee1;
//     border-radius: 20px;
//     // border: 6px solid transparent;
//     background-clip: content-box;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background-color: #a8bbbf;
// }


.blur-premium-content {
    filter: blur(5px);
    pointer-events: none;
    opacity: 0.5;
}

.blur-premium-content-extreme {
    filter: blur(30px);
    pointer-events: none;
    opacity: 0.25;
}


.pre {
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
}



.reset-anchor-styling {
    //    reset the default anchor tag styling
    color: inherit;
    text-decoration: none;
    transition: none;
    cursor: default;
    display: inline;

    &:hover {
        color: inherit;
        text-decoration: none;
        // opacity: 0.75;
    }
}

.anchor {
    color: inherit;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}



.paywall {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    user-select: none;
    pointer-events: none;
}

